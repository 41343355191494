import React from "react"

import Layout from "../components/Layout"
import * as data from "../assets/js/data"

const Settling = () => {
  return (
    <Layout>
      <section className="header">
        <h1 className="settling__header__title">Settling in Canada</h1>
      </section>
      <section className="settling">
        {Object.entries(data.topicCopy).map(([id, topic]) => (
          <a
            key={id}
            className="settling__item"
            href={`/settling/${id}/`}
            target="_blank"
            rel="noreferrer"
          >
            <div className={`settling__item-image o__${id}`} />
            <div className="settling__text">
              <h2 className="settling__title">{topic.title}</h2>
            </div>
          </a>
        ))}
      </section>
    </Layout>
  )
}

export default Settling
